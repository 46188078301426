/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    blockquote: "blockquote",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 250px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 37%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAByklEQVQoz02Qb2jMARjHH+vkhfx5saGtzuxGKaUTb5jI3lC8UJo3Nscx2vJv4xLXeMF2x1xnS0e2oo0SWSZebDnH1ZLbjLJ1ZS8kdSIRLza63+ern87yvPn0PE99e56PxTI5u/1R9uCzfGvjnWHbuzNl9YEL5acT5pYkyzvOXwLTREz3+kfJLDImi45+ORYdn/q+69mPiK0OlXgO1nYXHdjTuab1rP2cmlyXd5y5kpYBfkkLgUXAEqBK0g5J6yXtk7TfouPytY3xdaLncnVT9dajdmjgia0cnWPBmqQtr/RK6s07zj0gLKlV6DzQBmoBhoB6SUuB50DI4umWx6de3roRDgQaGyKXXtvJ3oGqjof3N17tOmPl88okxfKOcxPISjoh0SdpEAgCASADzJbULsln3eltarhTPlH8NOW3zlTSc7xZoVRWfd+0KfEmZ7/yTlBSMdAI1ID8oFXAdtARoB2YBdS5Gqx/pC52cXCzDt/1Du/uaU42Jd+pI/v7iiv4euZtEZLrawXgLbgrdS8CfID7aokkd7fYndujV4GZ/SO1iWupLYoPhYe7PmnDB8nOvXhv6dzkDEkLgAqg8r/Q+YXAskJQBVKpJM8f5FaFGuxZSNIAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Image of NHST logo\"\n        title=\"Image of NHST logo\"\n        src=\"/static/2f13737f2f95f2fc3071eefe3d346776/43fa5/nhst.png\"\n        srcset=\"/static/2f13737f2f95f2fc3071eefe3d346776/f8f3a/nhst.png 200w,\n/static/2f13737f2f95f2fc3071eefe3d346776/43fa5/nhst.png 250w\"\n        sizes=\"(max-width: 250px) 100vw, 250px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Signatu has an innovative approach to enable publishers, and other businesses with web sites, to manage users privacy rights when using the web sites. We are very happy with the results of the collaboration so far."), "\n"), "\n", React.createElement(_components.p, null, "Petter Gustafson, CTO, NHST Media Group"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/328db257a3f6e38d37daf7f9d600bb9f/europris_mer_til_overs.svg",
    alt: "Image of Europris logo"
  })), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "With Signatu’s innovative web tool, we are confident that our Privacy Policy is compliant, and with Trackerdetect we have a good overview of 3rd party trackers and services on our home pages. Signatu is also a good sparring partner, when needed. We are very pleased with the cooperation; and looking forward to future cooperation."), "\n"), "\n", React.createElement(_components.p, null, "Lars Ove Davidsen, DPO, Europris"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 40%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABJElEQVQY02N4xCqEBbGLPGLmfcgi+JCJ5xGLIHY1rEIMIAouzSL0iE34ERP/U3HlD9XNr3xC35fVPhaSe8TEDxLHoplF8BGH6CNWwYfM/A9ZBR6y8j9i4n0sLPd11bpXfuHfNm17xCyA12Y24Y+Nne+Kqz40tL3LLHxfVPnC0vnL3IWvfMM+T5vzgIERbDpWzewiDxk4v67b9CYh8+uy1W8TM78sWPo2IeN9Rf2H6qY3yTkPGJhwan7IwPNUSffbhi0vbD2+LlnxOjDiU8+kz9Nmv4lK/rp8zUsnn4cM7KDww6GZ+5mWybuc4hfWrm8iEl86+74vr3tfUf8mKuVDXetLt4CHDNxYQwvmZ1ahh4x8oDBj5IUE7ENmEPcRMz+uoIJoBgC7TsEygoWXfwAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"mlllogo\"\n        title=\"mlllogo\"\n        src=\"/static/7a80bc3a5e0400e514085bd84aafe28d/7842b/mlllogo.png\"\n        srcset=\"/static/7a80bc3a5e0400e514085bd84aafe28d/f8f3a/mlllogo.png 200w,\n/static/7a80bc3a5e0400e514085bd84aafe28d/6a8a8/mlllogo.png 400w,\n/static/7a80bc3a5e0400e514085bd84aafe28d/7842b/mlllogo.png 800w,\n/static/7a80bc3a5e0400e514085bd84aafe28d/5ece7/mlllogo.png 1200w,\n/static/7a80bc3a5e0400e514085bd84aafe28d/0d4f8/mlllogo.png 1600w,\n/static/7a80bc3a5e0400e514085bd84aafe28d/5d432/mlllogo.png 1993w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Signatu offers a great tool to ensure compliance with some of the very basic requirements provided by international standards in data protection, in particular the EU GDPR. It is innovative, easy to use and a very much recommended alternative to other solutions on the market."), "\n"), "\n", React.createElement(_components.p, null, "Lukas Bühlmann, Partner and Head Digital, Data Privacy & E-Commerce at Meyerlustenberger Lachenal Ltd. (MLL)"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 235px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 38.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABBUlEQVQY042Qy0rDQBSG85IuxaXP4U5REPQttN3ZiGCSldSSGOsDKIjWmuuYmZyZM3eJdWerfqvDB/+5BX4Tzvm/CNYHpXJKO0SH+KOn2xh21nrvIbtf7B0s94/h7mGwX3LNZAAgbUsp5QBCCAGgvac3s6ft3cetHXIVdYg9pZxzDgNVWRmtv8P5fH4+Hs/S9DqKbtM0TpJkOiVZXhyevB+ddtP0MkkmkzDL84swjJP4bDSqm2a1f6CKUpeVLirVtOptia8LWdWqbpAxXTeGfODziy4rQ4hqWtv3Q03Z6vrAAjc9WOBWoOmoZcwJNFxoRMu5QzlIAMuFFWilNKx3Uv727X/yCVUsxCwUJl7YAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Image of Beranek logo\"\n        title=\"Image of Beranek logo\"\n        src=\"/static/6525087639564e0405780d67af722451/7cea4/beranek.png\"\n        srcset=\"/static/6525087639564e0405780d67af722451/f8f3a/beranek.png 200w,\n/static/6525087639564e0405780d67af722451/7cea4/beranek.png 235w\"\n        sizes=\"(max-width: 235px) 100vw, 235px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Trackerdetect is a reliable tool to evaluate your cookies and keep up the tracking."), "\n"), "\n", React.createElement(_components.p, null, "Nicole Beranek Zanon, Partner at de la cruz beranek Rechtsanwälte AG"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
